<template>
  <section>
    <div class="pageBradcrumbs">
      <div class="containerLarge">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb small">
            <li class="breadcrumb-item">
              <b-button @click="toGlobal('/marine', 'marine')">Home</b-button>
            </li>
            <li class="breadcrumb-item">
              <b-button @click="toGlobal('/size-your-system', 'marine')">Size your system</b-button>
            </li>
          </ol>
        </nav>
      </div>
    </div>
    <div class="banner">
      <div
        class="bannerImage"
        :style="{
          'background-image':
            'url(' + require('../assets/img/banner/size-your-system.jpg') + ')',
        }"
      >
        <div class="containerLarge">
          <div class="pageTitle-banner text-center">
            <div class="pageTitle-content">
              <h1 class="mt-4">Scanjet • Tank cleaning system</h1>
              <div class="divider"></div>
              <p class="lead">
                Size Your System
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="containerLarge">
      <div class="row mt-5">
        <div class="col-md-10 offset-md-1 col-lg-8 offset-lg-2">
            
            <!-- NESSUN CONFIGURATORE -->  
            <p class="lead text-sm-left text-lg-center pb-lg-4">The configuration of these products is complex. <br>
            Please <router-link class="linkClick" to='/marine-contacts'><u>Contact us</u></router-link> to receive the technical feedback for your perfect configuration.</p>
            
            <p class="lead text-sm-left text-lg-center">You can find out more about these products by visiting our institutional 
            <a target="_blank" rel="noopener" href="https://www.alfalaval.com/industries/marine-transportation/marine/tank-cleaning/">
            <u>website</u></a>. </p>  

        </div>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  data: () => ({
    categoria: '',
    id: '',
  }),
  mounted(){
    this.categoria = this.$route.query.category;
    this.id = this.$route.query.id;
  },

  computed: {
    getCurrentPath(){
      return this.$store.getters.getCurrentPath;
    }
  },

  methods: {
    toGlobal(str, current){
      if (str !== this.getCurrentPath){
        this.$store.commit('setCurrentGlobal', current);
        this.$store.commit('setCurrentPath', str);
        this.$router.push(str);
      }      
    },
  }
};
</script>
